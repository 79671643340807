import { Stream, StreamProps } from "@cloudflare/stream-react"
import React from "react"
import CloseIco from "../assets/img/close-circle.inline.svg"

interface StreamPlayerProps
  extends Omit<StreamProps, "controls" | "autoplay" | "poster"> {
  onClick: () => void
}

const StreamPlayer = (props: StreamPlayerProps) => {
  const { onClick, ...restProps } = props;
  return (
    <div
      className="w-full h-full fixed inset-0 bg-black bg-opacity-70 backdrop-blur-lg flex justify-center items-center z-30"
      onClick={onClick}
    >
      <div
        className={props.className}>
        <CloseIco className="absolute right-[0.625rem] top-[0.625rem] cursor-pointer z-30" onClick={onClick} />
        <Stream
          {...restProps}
          responsive
          title="Techuz Infoweb work environment"
        />
      </div>
    </div>
  )
}

export default React.memo(StreamPlayer)
