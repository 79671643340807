import React, { useState } from "react"
import PlayIco from "../assets/img/play-ico.inline.svg"
import StreamPlayer from "./StreamPlayer"
import VideoImage from "../assets/img/video-image-main1.webp"

type propType = {
    class?: string
}

const StreamComponent = (prop: propType) => {
    const [shouldShow, setShouldShow] = useState(false)
    const handleVisibility = (showHide: boolean) => {
        showHide ? document.body.classList.add('stream-opened') : document.body.classList.remove('stream-opened')
        setShouldShow(showHide)
    }
    return (
        <>
            <div
                className={`relative w-[24.188rem] h-[14.188rem] border border-primary p-3 rounded-3xl ${prop.class}`}
            >
                <img loading="eager" src={VideoImage} alt="Techuz Infoweb work environment" className="-z-10 h-full w-full rounded-2xl" />
                <button
                    type="button"
                    title="play button"
                    onClick={() => handleVisibility(true)}
                    className={`flex items-center justify-center text-2xl rounded-full bg-orangecol uppercase text-white font-gorditamedium cursor-pointer
                    w-11 h-11 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 hover:bg-orangecol transition-all duration-700 z-10 hover:md:opacity-100`}
                >
                    <div>
                        <PlayIco />
                    </div>

                    <div className="waves-block rounded-full">
                        <div className="waves wave-1 absolute h-20 w-20 top-[-18px] left-[-20px] bg-orangecol -z-10 rounded-full delay-[0s]"></div>
                        <div className="waves wave-2 absolute h-20 w-20 top-[-18px] left-[-18px] bg-orangecol -z-10 rounded-full delay-[1s]"></div>
                        <div className="waves wave-3 absolute h-20 w-20 top-[-18px] left-[-18px] bg-orangecol -z-10 rounded-fulldelay-[2s]"></div>
                    </div>
                </button>
            </div>
            {shouldShow && <StreamPlayer
                src={`https://customer-4iwgkzn69idft9ab.cloudflarestream.com/9aa1e5d252756e8160aa8a59e3343b17/iframe?autoplay=true&muted=true`}
                className="absolute h-full w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl md:max-w-[50rem] md:h-[28.125rem] !p-0 overflow-hidden bg-black"
                onClick={() => handleVisibility(false)}
            />}
        </>
    )
}

export default React.memo(StreamComponent)
